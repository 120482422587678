<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }"
              ><i class="fa fa-home"></i
            ></b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'GroupbuyingsList' }"
              >開團管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>設定門市庫存品照片</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center" style="flex-direction: column">
          <h4 class="font-weight-bold">設定商品照片</h4>
        </div>
      </div>

      <div class="mb-2 text-danger">商品照片上傳最少需2張，上限為10張</div>
      <div class="mb-2">亦可先【編輯團購設定】後再上傳照片</div>
      <div class="mb-2">
        <b-form-group>
          <b-form-select
            class="form-control form-control-sm col-12 col-xl-6"
            v-model="currentProductId"
            :disabled="!canClick"
          >
            <b-form-select-option
              v-if="productOptions && productOptions.length > 1"
              :value="null"
              disabled
            >
              請選擇商品名稱
            </b-form-select-option>
            <b-form-select-option
              v-for="productOption in productOptions"
              :key="productOption.id"
              :value="productOption.id"
            >
              {{ productOption.title }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </div>

      <div v-if="croppingImage || selectedProductImage" class="s-edit-section">
        <div v-if="croppingImage" class="mb-2 form-inline">
          <b-form-select
            v-model="selectedMask"
            :options="maskOptions"
            size="sm"
            class="s-mask-select mr-3"
            @change="handleMaskSelect()"
            :disabled="!canClick"
          ></b-form-select>
          <button
            class="btn btn-danger btn-sm s-cancel-btn"
            type="button"
            @click="resetCropping"
            :disabled="!canClick"
          >
            取消
          </button>
          <button
            class="btn btn-success btn-sm"
            type="button"
            @click="cropImage"
            :disabled="!canClick"
          >
            確定
          </button>
        </div>

        <div v-if="selectedProductImage" class="mb-2 form-inline s-btn-menu">
          <button
            v-if="productImages.length < 10"
            class="btn btn-success btn-sm s-cancel-btn"
            type="button"
            @click="openFileSelector"
            :disabled="!canClick"
          >
            新增相片
          </button>
          <button
            class="btn btn-primary btn-sm"
            type="button"
            @click="reCrop"
            :disabled="!canClick"
          >
            重新裁切
          </button>
        </div>

        <div v-if="selectedProductImage">
          <div class="s-cropper-inner">
            <img
              v-if="(selectedProductImageIndex === 0 && selectedProductImage.mask_image_url)"
              :src="selectedProductImage.mask_image_url"
              style="position: absolute; max-width: calc(100% - 2rem);"
            />
            <img :src="selectedProductImage.pic_url" style="max-width: 100%;" />
          </div>
        </div>

        <div class="s-edit-section-cropper">
          <VueCropper class="sr-only" :src="croppingImage" :aspect-ratio="aspectRatio" :initialAspectRatio="aspectRatio" ref="cropper" />
          <img :id="randomId" :src="croppingImage" />
        </div>
      </div>

      <input class="s-file-input" type="file" name="image" accept="image/*" ref="input" @change="loadImage" :multiple="true"/>

      <div v-if="!selectedProductImage && !croppingImage && productImages.length < 10" class="mb-5">
        <div class="s-cropper-inner">
          <div style="position: relative;">
            <button
              class="btn s-trigger-btn"
              :disabled="!canClick"
              @click="openFileSelector"
            >
              <template v-if="canClick">
                <div class="add-image-icon">＋</div>
                <div>新增</div>
              </template>
              <div v-if="!canClick" v-html="uploadingText()"></div>
            </button>
          </div>
        </div>
      </div>

      <VueDraggable
        class="list-unstyled s-image-list mb-5"
        handle=".s-draggable"
        tag="ul"
        v-model="productImages"
        :move="checkMove"
        @end="dragEnd"
      >
        <li
          class="s-image-list-item"
          :key="i"
          v-for="(image, i) in productImages"
        >
          <div class="s-draggable">
            <div
              v-if="(i === 0 && canClick && image.mask_image_url)"
              class="product-mask"
              :style="{'background-image': 'url(' + image.mask_image_url + ')'}"
            ></div>
            <div v-if="!canClick" class="product-mask" :style="{'background-image': 'url(' + loadingImage + ')'}">
            </div>
            <img
              class="s-cropped-image"
              :src="image.pic_url"
            />
            <button
              class="btn btn-secondary s-delete-btn"
              type="button"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" style="width: 22px;">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
              </svg>
            </button>
          </div>

          <!-- 發現手機板 chrome 的 click 似乎與 draggable 處理 event 衝突, 先簡單用 touchstart 解掉 -->

          <div class="s-list-item-btn-menu">
            <b-button
              :disabled="!canClick"
              class="mr-1"
              variant="outline-dark"
              @click="selectImage(image, i)"
            >編輯</b-button>
            <b-button
              :disabled="!canClick"
              variant="outline-danger"
              @click="deleteImage(i)"
            >刪除</b-button>
          </div>
        </li>
      </VueDraggable>
      <div class="d-flex justify-content-center">
        <!-- <b-button class="mr-3" variant="outline-success" @click="saveDraft">存成草稿</b-button> -->
        <!-- <b-button variant="success" @click="save">確定開團</b-button> -->
        <b-button class="mr-3" variant="success" @click="gotoScanProduct" :disabled="!canClick"
          >掃描<br>其他商品</b-button
        >
        <b-button variant="success" @click="next" :disabled="!canClick"
          >編輯<br>團購設定</b-button
        >
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import VueDraggable from "vuedraggable";
import 'croppr/dist/croppr.css'
import 'cropperjs/dist/cropper.css'
import Croppr from 'croppr'
import VueCropper from 'vue-cropperjs'
// import ImageSelectorGroupbuying from "@/components/ImageSelectorGroupbuying";
import productApi from "@/apis/product";
import LoadingImage from "@/assets/images/image-loading.gif";
import { v4 as uuidv4 } from 'uuid'
import { loadImageData, crop } from "./GroupbuyingImageUtils.js";

Croppr.prototype.attachOverlayEvents = function () {} // 這段負責用來解決『點了截圖畫面，框框會亂跑』的問題

export default {
  components: {
    VueDraggable,
    VueCropper,
    // ImageSelectorGroupbuying,
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  data: () => ({
    currentProductId: null,
    branchId: null,
    product: null,
    images: [],
    base64Images: [],
    groupOptions: null,
    productOptions: null,
    canClick: true,
    canUpload: true,
    canUploadQuota: 10,
    productImages: [],
    croppr: null,
    aspectRatio: 1/1, // 裁切比例
    croppingImage: null, // 正在裁切的那張圖片
    croppingMode: "create", // 定義現在裁切的是新增圖片還是編輯圖片(新增 create, 編輯 edit)
    croppingImageId: null, // 現在編輯裁切的圖片 id
    masks: [], // 遮罩圖
    isCropping: false, // 是否正在裁切中
    selectedMask: null, // 目前選擇的遮罩圖片網址
    randomId: null,
    selectedProductImage: null,
    selectedProductImageIndex: null,
    uploadingTotal: null,
    uploadingCurrent: null,
  }),
  computed: {
    ...mapState("general", {
      currentBranch: (state) => state.currentBranch,
    }),
    loadingImage() {
      return LoadingImage;
    },
    maskOptions() {
      if (this.masks.length === 0) {
        return { text: "無遮罩圖片", value: null, default_selected: false };
      }

      let masks = this.masks;

      let forceFirst = this.masks.findIndex((option) => option.force_first_mask == true);

      // 編輯中的圖片要判斷是否為首圖
      if (this.croppingImageId !== null) {
        let index = this.productImages.findIndex((image) => image.id === this.croppingImageId);
        if (index !== 0) {
          masks.unshift({ name: "無遮罩圖片", url: null, default_selected: false });
        }
      } else {
        if (this.productImages.length !== 0 || forceFirst < 0) {
          masks.unshift({ name: "無遮罩圖片", url: null, default_selected: false });
        }
      }

      return masks.map((mask) => {
        return {
          text: mask.name,
          value: mask.url,
          default_selected: mask.default_selected ?? false,
        };
      })
    },

  },
  watch: {
    currentProductId(val) {
      this.$router
        .push({ name: "StoreGroupbuyingUpdateImage", params: { id: val } })
        .catch(() => {});

      if (!this.productOptions) {
        return;
      }

      this.product = this.productOptions.find((opt) => opt.id == val);
      this.productImages = this.product.images;
      this.resetCropping();
    },
  },
  created: function () {
    if (this.currentBranch.id != this.$route.params.branch_id) {
      this.$router
        .push({ name: "StoreGroupbuyingScanProduct" })
        .catch(() => {});
      return;
    }

    window.addEventListener("resize", this.setImageButtonHeight);
    this.currentProductId = this.$route.params.id;
    this.branchId = this.$route.params.branch_id;
    this.getProductList();
  },
  destroyed() {
    window.removeEventListener("resize", this.setImageButtonHeight);
  },
  mounted() {
    this.setImageButtonHeight();
  },
  methods: {
    uploadingText() {
      if (this.uploadingTotal == null) {
        return '更新中...';
      } else {
        return `上傳中...<br>(${this.uploadingCurrent}/${this.uploadingTotal})`;
      }
    },
    // 上傳中的時候，不要讓他作任何動作，不可移動照片
    checkMove() {
      return this.canClick;
    },

    // 因為 image 寬度是動態算出來的（需要三個並列所以除三等份），而這個加入照片的寬度沒有照片支撐，需要先算出目前的寬度然後直接設為高度
    setImageButtonHeight() {
      const addImageButton = document.querySelector("#addImageButton");
      if (addImageButton) {
        addImageButton.style.height = addImageButton.clientWidth + "px";
      }
    },

    // 取得商品資料 & 更新商品圖片
    async getProductList() {
      try {
        const { data } = await productApi.getUngroupbuyingProducts({
          branch_id: this.branchId,
        });

        this.productOptions = data.data;
        this.product = this.productOptions.find(
          (opt) => opt.id == this.currentProductId
        );
        this.productImages = this.product.images;
        this.masks = data.meta.masks;
      } catch (err) {
        console.log(err);
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "查無商品資料",
        });
      }
    },
    // 更新所有圖片的順序
    async sortImages(isForce = false) {
      if (this.canClick === false && !isForce) return;

      this.canClick = false;

      await productApi.sortImagesViaIds(this.currentProductId, {
        image_ids: this.productImages.map((image) => image.id),
      });

      await this.getProductList();

      this.canClick = true;
    },

    // 刪除指定圖片
    deleteImage(index) {
      this.productImages.splice(index, 1);
      this.resetCropping();
      this.sortImages();
    },
    next() {
      // 如果這個商品已經有團購了，就直接進 Draft page，反之則是 Create page
      if (this.product.groupbuyings.length > 0) {
        this.$router.push({
          name: "StoreGroupbuyingDraft",
          query: {
            product_id: this.product.id,
          },
        });
      } else {
        this.$router.push({
          name: "StoreGroupbuyingCreate",
          params: {
            branch_id: this.branchId,
          },
        });
      }
    },

    // 返回掃描商品條碼頁
    gotoScanProduct () {
      this.$router.push({
        name: "StoreGroupbuyingScanProduct"
      });
    },
    // 拖拉照片結束時
    dragEnd() {
      this.sortImages();
    },
    // 點擊新增時，先觸發 file input.
    openFileSelector() {
      this.$refs.input.click()
    },
    async loadImage(event) {
      this.selectedProductImage = null;
      this.selectedProductImageIndex = null;

      // 如果是單個圖檔上傳，就維持原本模式
      if (event.target.files.length === 1) {
        if (this.productImages.length >= 10) {
          this.$swal.fire({
            title: "提醒",
            type: "warning",
            text: "圖片最多只限上傳10張",
          });
          return;
        }

        let file = event.target.files[0];
        this.randomId = `image-${uuidv4()}`
        await this.$nextTick()
        const reader = new FileReader()

        let index = this.maskOptions.findIndex((option) => option.default_selected == true);
        if (this.productImages.length === 0 && index >= 0) {
          this.selectedMask = this.maskOptions[index].value;
        } else {
          this.selectedMask = null;
        }

        reader.onload = async (event) => {
          this.croppingImage = event.target.result
          this.croppingMode = 'create';
          this.croppingImageId = null;
          this.$refs.input.value = null
          await this.$nextTick();
          this.$refs.cropper.replace(this.croppingImage)
          this.$nextTick(this.initCroppr)
          this.$nextTick(this.coverMask)
        }

        reader.readAsDataURL(file);
        return;
      }

      // 這裡負責處理多圖上傳
      this.canClick = false;
      const files = [...event.target.files];

      let _this = this;

      this.uploadingTotal = files.length;

      for (let i = 0; i < files.length; i ++) {
        this.uploadingCurrent = i + 1;
        let file = files[i];
        let index = _this.maskOptions.findIndex((option) => option.default_selected == true);
        if (_this.productImages.length === 0 && index >= 0) {
          _this.selectedMask = _this.maskOptions[index].value;
        } else {
          _this.selectedMask = null;
        }

        if (_this.productImages.length >= 10) {
          _this.$swal.fire({
            title: "提醒",
            type: "warning",
            text: "圖片最多只限上傳10張",
          });
          break;
        }

        let imageBase64 = await loadImageData(file);
        imageBase64 = await this.resizeOriginalImage(imageBase64);

        let croppedCanvas = await crop(imageBase64, 1 / 1);
        let croppedBase64 = croppedCanvas.toDataURL('image/jpeg', 0.5);

        try {
          // 送上後端
          await productApi.uploadProductImages(
            _this.currentProductId,
            {
              original_image: imageBase64,
              cropped_image: croppedBase64,
              mask_image_url: _this.selectedMask,
            }
          );

          _this.productImages.push({
            pic_url: croppedBase64
          });
        } catch (error) {
          console.log(error);
          if (error.response.data.message) {
            _this.$swal.fire({
              title: "上傳失敗",
              type: "error",
              text: error.data.response.message,
            });
          }
        }
      }

      this.uploadingTotal = null;
      this.uploadingCurrent = null;

      await this.getProductList();

      this.canClick = true;
    },
    // 初始化 Croppr (非 VueCropper，這裡同時存在兩個 Crop 套件)
    initCroppr() {
      let _this = this;

      // cropper 跟 vue-cropperjs 的比例設定剛好顛倒
      const options = {
        aspectRatio: 1/this.aspectRatio,
        startSize: [100, 100, '%'],
        maxSize: [1024, 1024, 'px'],
        minSize: [100, 100, 'px'],
        onCropMove: function() {
          _this.coverMask();
        },
        onInitialize: function() {
          _this.coverMask();
        },
      }

      this.croppr = new Croppr(`#${this.randomId}`, options)
    },
    // 套用遮罩（這裡因為一些緣故，所以魔改了套件本身的 class 來添加遮罩圖片。
    coverMask() {
      let overlay = document.getElementsByClassName('croppr-region');
      if (overlay.length > 0) {
        overlay = overlay[0];
        if (this.selectedMask !== null) {
          overlay.style.cssText = `
            background-image: url("${this.selectedMask}");
            background-size: contain;
          `;
        } else {
          overlay.style.cssText = '';
        }
      }
    },
    // 選擇遮罩
    handleMaskSelect() {
      this.croppr.reset();
      this.coverMask();
    },
    // 重置正在裁切的圖片
    async resetCropping() {
      this.croppingImage = null;
      this.selectedProductImage = null;
      this.croppingImageId = null;
      this.selectedMask = null;
      if (this.croppr) {
        this.croppr.destroy();
        this.croppr = null;
      }
      await this.$nextTick();
    },
    // 裁切
    async cropImage() {
      this.canClick = false;

      const croppedImage = this.$refs.cropper
        .setData(this.croppr.getValue())
        .getCroppedCanvas({ width: 1024, height: 1024 })
        .toDataURL('image/jpeg', 0.5)

      const originalImage = await this.resizeOriginalImage(this.croppingImage);
      const croppingImageId = this.croppingImageId;
      const selectedMask = this.selectedMask;

      this.resetCropping();

      // 直接送上後端儲存
      try {
        if (this.croppingMode === 'create') {
          this.uploadingTotal = 1;
          this.uploadingCurrent = 1;
          await productApi.uploadProductImages(
            this.currentProductId,
            {
              original_image: originalImage,
              cropped_image: croppedImage,
              mask_image_url: selectedMask,
            }
          );

          if (selectedMask !== null) {
            await this.getProductList();
            this.productImages.unshift(this.productImages.pop());
            await this.sortImages();
          } else {
            this.productImages.push({
              pic_url: croppedImage
            });
          }

          this.uploadingTotal = null;
          this.uploadingCurrent = null;
        } else {
          await productApi.updateProductImage(
            this.currentProductId,
            croppingImageId,
            {
              cropped_image: croppedImage,
              mask_image_url: selectedMask,
            }
          );

          if (selectedMask !== null) {
            let index = this.productImages.findIndex((image) => image.id == croppingImageId);
            let image = this.productImages[index];
            this.productImages.splice(index, 1)
            this.productImages.unshift(image);
            await this.sortImages(true);
          }
        }

        await this.getProductList();
        await this.$nextTick();
      } catch (err) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "上傳圖片失敗",
        });
      }

      this.canClick = true;
    },

    // 把原圖縮小
    async resizeOriginalImage(imageBase64) {
      // create an off-screen canvas
      var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      let originalImage = await this.base64toImage(imageBase64);

      let width = originalImage.width;
      let height = originalImage.height;
      console.log(width, height);
      if (width > 1400) {
        let ratio = 1400 / width;
        width = width * ratio;
        height = height * ratio
      }

      canvas.width = width;
      canvas.height = height;

      // draw source image into the off-screen canvas:
      ctx.drawImage(originalImage, 0, 0, width, height);

      return canvas.toDataURL('image/jpeg', 0.6);
    },
    async base64toImage(base64) {
      return new Promise((resolve) => {
        var image = new Image();
        image.onload = function() {
          resolve(image);
        };
        image.src = base64;
      });
    },
    // 用戶點擊圖片以後
    async selectImage(image, i) {
      if (!this.canClick) {
        return;
      }

      this.selectedProductImage = image;
      this.selectedProductImageIndex = i;
      this.croppingImage = null;
      this.croppingImageId = null;
      window.scrollTo({top: 200, behavior: 'smooth'});
    },
    // 重新裁切已上傳過的圖
    async reCrop() {
      this.canClick = false;
      let image = this.selectedProductImage;
      this.selectedProductImage = null;

      if (!image.original_image) {
        this.$swal("錯誤", "後台版本已更新，如需重新裁切請重新上傳圖檔", "error");
        this.canClick = true;
        return;
      }

      if (image.mask_image_url && this.productImages.findIndex((ele) => ele.id == image.id) === 0) {
        this.selectedMask = image.mask_image_url;
      } else {
        this.selectedMask = null;
      }

      this.randomId = `image-${uuidv4()}`;
      this.croppingMode = 'edit';
      this.croppingImageId = image.id;
      this.croppingImage = await this.getBase64FromUrl(image.original_image.url);

      await this.$nextTick();
      this.$refs.cropper.replace(this.croppingImage);
      this.$nextTick(this.initCroppr);
      this.$nextTick(this.coverMask);
      this.canClick = true;
    },
    // Get image base64 from url
    async getBase64FromUrl(url) {
      const data = await fetch(url);
      const blob = await data.blob();

      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        }
      });
    },
  },
};
</script>

<style scoped>
.s-image-list {
  margin: -0.75rem;
  display: flex;
  flex-wrap: wrap;
  min-height: 100px;
}

.s-image-list-item {
  position: relative;
  /* 因為希望三個並列所以寬度為 100% 除以三等份，扣掉兩邊的 margin */
  width: calc((100% / 3) - 0.75rem * 2);
  border: 1px solid #ddd;
  margin: 0.5rem 0.75rem;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-cropped-image {
  width: 100%;
  height: 100%;
}

.s-delete-btn {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  opacity: 60%;
}

.s-image-selector {
  width: 100%;
  height: 100%;
}

.s-trigger-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.add-image-icon {
  font-weight: 800;
  margin-bottom: 8px;
}

.product-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
}
.s-cropper-inner {
  position: relative;
  width: 100%;
  max-width: 480px;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 0 0 0 rgb(90 113 208 / 11%), 0 4px 16px 0 rgb(167 175 183 / 33%);
  border: solid 1px #dde4eb;
  background-color: white;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.s-mask-select {
  max-width: 40%;
}

.s-file-input {
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
}

.s-cancel-btn {
  margin-right: 1rem;
}

.s-list-item-btn-menu {
  position: absolute;
  bottom: -35px;
  display: flex;
  flex-wrap: wrap;
}

.s-list-item-btn-menu .btn {
  padding: 5px 5px;
}

.s-edit-section {
  background-color: #eeeeee;
  border-radius: 10px;
  display: inline-block;
  padding: 1rem;
  margin-bottom: 30px;
  box-shadow: 0 0 0 0 rgb(90 113 208 / 11%), 0 4px 16px 0 rgb(167 175 183 / 33%);
  border: solid 1px #dde4eb;
}

.s-add-btn {
  min-width: calc((100% / 3) - 0.75rem * 2);
}

.s-edit-section-cropper {
  max-width: 480px;
  width: 100%;
}

.s-btn-menu {
  display: flex;
  justify-content: center;
}
</style>
